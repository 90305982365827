<template>
  <div class="personal_center_data_page bbox" v-if="userinfo">
    <div class="wrapper d-flex flex-column">
      <div class="avatar_group_feature flex-shrink-0 d-flex flex-column align-center justify-center">
        <div class="avatar_box cp">
          <img :src="avatar_preview" alt="" class="avatar_img" v-if="avatar_preview">
          <img :src="`${$avatar_url}${userinfo.thumbnail}`" alt="" class="avatar_img" v-if="userinfo.thumbnail && !avatar_preview">
          <div class="hover un_sel d-flex align-center justify-center"
            @click.stop="$refs.upload_avatar.click()"
          >
            <div class="text">修改头像</div>
          </div>

          <input
            type="file"
            accept="image/*"
            style="display:none;"
            ref="upload_avatar"
            class="upload_avatar"
            @change="uploadAvatar"
          >
        </div>
        <div class="info_box d-flex align-start justify-center bbox">
          <div class="placeholder"></div>
          <div class="username">{{userinfo.userName}}</div>
          <img
            src="@/assets/images/personal_center/vip_mark.png"
            alt=""
            class="vip cp"
            v-if="userinfo.userVip"
            @click.stop="$router.push({name: 'personal_center_vip_and_consumption'})"
          >
          <div class="placeholder"
            v-else
          ></div>

        </div>
      </div>
      <div class="avatar_group"
        v-if="0"
      >
        <div class="avatar">

          <img :src="avatar_preview" alt="" class="avatar_img" v-if="avatar_preview">
          <img :src="`${$avatar_url}${userinfo.thumbnail}`" alt="" class="avatar_img" v-if="userinfo.thumbnail && !avatar_preview">
        </div>
        <div class="fun_box">
          <img src="@/assets/images/personal_center/vip_mark.png" alt="" class="vip"
            v-if="userinfo.userVip"
          >
          <div class="placeholder"
            v-else
          ></div>
          <span
            class="mod_avatar cp un_sel"
            @click.stop="$refs.upload_avatar.click()"
          >修改头像</span>
        </div>
      </div>
      <div class="data_group">
        <div class="extend_group">
          <div
            class="extend_title_group cp un_sel"
          >
            <div class="title">基本信息</div>
          </div>
        </div>

        <div class="info_group">
          <div class="key">妙行号:</div>
          <div class="phone_box">
            <div class="val">{{userinfo.phone}}</div>
            <div class="mod_phone cp" @click="dialogFormVisible = true" v-if="0">修改绑定手机 ></div>
          </div>
        </div>
        <div class="info_group" style="margin-bottom:-6px;">
          <div class="key">昵称:</div>
          <div class="nickname_box">
            <!-- <div class="nickname val">赵大帅</div> -->
            <input
              type="text"
              class="mod_nickname"
              :class="{on: switch_mod_nickname}"
              v-model="userinfo.userName"
              @keyup.enter="$refs.userName.blur();"
              ref="userName"
              maxlength="20"
              @focus="switch_mod_nickname = true"
              @blur="changeUserInfo('userName',userinfo.userName);switch_mod_nickname = false;"
            >
          </div>
        </div>
        <div class="info_group" >
          <div class="key">性别:</div>
          <div class="gender_group">
            <div class="female_group gender_box" :class="{on: userinfo.userSex == '女'}" @click="$set(userinfo,'userSex', '女');changeUserInfo('sex','女');">
              <div class="select"></div>
              <img src="@/assets/images/personal_center/female.png" alt="" class="icon">
            </div>
            <div class="male_group gender_box" :class="{on: userinfo.userSex == '男'}" @click="$set(userinfo,'userSex', '男');changeUserInfo('sex','男');">
              <div class="select"></div>
              <img src="@/assets/images/personal_center/male.png" alt="" class="icon">
            </div>
          </div>
        </div>
        <div class="info_group">
          <div class="key">邮箱:</div>
          <div class="input_group">
            <!-- <div class="nickname val">赵大帅</div> -->
            <input
              type="text"
              class="input"
              v-model="userinfo.email"
              ref="email"
              placeholder="请输入邮箱地址"
              @blur="changeUserInfo('email',userinfo.email)"
            >
          </div>
        </div>
        <div class="info_group" style="padding-bottom: 14px;">
          <div class="key">地区:</div>
          <div class="area_group">
            <div class="select_switch_group cp" @click.stop="switch_select_area=true;">
              <div
                class="text"
                :class="{un_input: !area_selected}"

              >{{area}}</div>
              <div
                class="icon"
              ></div>
              <div class="select_area_model" v-if="switch_select_area">
                <select-area @closeSelectArea="closeSelectArea"></select-area>
              </div>
            </div>
          </div>
        </div>

        <div class="info_group" style="align-items: flex-start;">
          <div class="key" style="padding-top: 14px;">个性签名:</div>
          <div class="signature_group">
            <div class="signature">{{userinfo.personalDescribe}}</div>
            <textarea name="" id="" class="input_signature" v-model="userinfo.personalDescribe" ref="input_signature" @blur="changeUserInfo('personalDescribe',userinfo.personalDescribe);"></textarea>
          </div>
        </div>
        <div class="line"></div>
        <div class="extend_group">
          <div
            class="extend_title_group cp un_sel"
            :class="{on: switch_realname}"
            @click.stop="switch_realname = !switch_realname;"
          >
            <div class="icon"></div>
            <div class="title">实名认证信息</div>
          </div>
          <template v-if="switch_realname">
            <div class="info_group" >
              <div class="key">姓名:</div>
              <div class="val">{{userinfo.userRealName}}</div>
            </div>
            <div class="info_group">
              <div class="key">身份证号:</div>
              <div class="id_group">
                <div class="val">{{userinfo.userCardNo}}</div>
                <div class="mod" v-if="0">修改实名认证 ></div>
              </div>
            </div>
          </template>
        </div>
        <div class="line"></div>
        <div class="extend_group">
          <div
            class="extend_title_group cp un_sel"
            :class="{on:switch_otherinfo}"
            @click.stop="switch_otherinfo = !switch_otherinfo;"
          >
            <div class="icon"></div>
            <div class="title">其他信息</div>
          </div>
          <template v-if="switch_otherinfo">
            <div class="info_group">
              <div class="key">机构:</div>
              <input type="text" class="val input"
                @keyup.enter="$refs.ownedEnterprises.blur();"
                ref="ownedEnterprises"
                @blur="changeUserInfo('ownedEnterprises',userinfo.ownedEnterprises)"
                v-model="userinfo.ownedEnterprises"
              >
            </div>
            <div class="info_group">
              <div class="key">职务:</div>
              <input type="text" class="val input"
                @keyup.enter="$refs.userPosition.blur();"
                ref="userPosition"
                @blur="changeUserInfo('userPosition',userinfo.userPosition)"
                v-model="userinfo.userPosition"
              >
            </div>
            <div class="info_group">
              <div class="key">身份:</div>
              <el-popover
                placement="bottom"
                trigger="click"
                width="270"
                class="identity_group"
                v-model="switch_identity"
                popper-class="pc_data_popover"
                >
              <div class="identity_list">
                <div class="list" @click.stop="selectIdentity(1)">教师</div>
                <div class="list" @click.stop="selectIdentity(2)">学生</div>
                <div class="list" @click.stop="selectIdentity(3)">从业者</div>
              </div>
                <div class="identity_bar cp" slot="reference">
                  <div class="text">{{transform_identity}}</div>
                  <div class="icon"></div>
                </div>
              </el-popover>
            </div>
          </template>
        </div>
        <div class="line"></div>
        <!-- 个人能力成长数据 -->
        <div class="extend_group" v-if="1">
          <div
            class="extend_title_group cp un_sel"
            :class="{on:switch_growing_data}"
            @click.stop="switch_growing_data = !switch_growing_data;"
          >
            <div class="icon"></div>
            <div class="title">个人能力成长数据</div>

          </div>
          <div class="growing_data_wrap"
            v-show="switch_growing_data"
          >
            <!-- <div
              class="growing_data_group"
              ref="growing_data_group"
            > </div> -->
            <radar-growing-data
              :userId="Number($tools.getUserId())"
            ></radar-growing-data>
          </div>
        </div>
        <el-dialog title="修改绑定手机" class="mod_phone_group" :visible.sync="dialogFormVisible">
          <div class="title_group" slot="title">修改手机</div>
          <div class="mod_phone_box">
            <div class="info_group">
              <div class="key">手机号码:</div>
              <div class="input_box">
                <input type="text" class="input">
              </div>
            </div>
            <div class="info_group">
              <div class="key">短信验证码:</div>
              <div class="input_box">
                <input type="text" class="input">
                <div class="btn cp un_sel">点击获取</div>
              </div>
            </div>
          </div>
          <div slot="footer" class="footer_group">
            <div class="btn cp un_sel sure" @click="dialogFormVisible = false">确认</div>
            <div class="btn cp un_sel cancel" @click="dialogFormVisible = false">取消</div>
          </div>
        </el-dialog>

      </div>

    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts';
import SelectArea from '@/components/Module/SelectArea.vue';
import RadarGrowingData from '@/components/radar/growingData.vue';
export default {
  components:{
    SelectArea,
    RadarGrowingData,
  },
  data(){
    return {
      userinfo: this.$tools.deepClone(this.$tools.getCookie('user')),
      dialogFormVisible: false,
      switch_mod_nickname: false,
      switch_select_area: false,
      switch_realname: true,
      switch_otherinfo: true,
      switch_identity: false,
      switch_growing_data: true,
      avatar_preview: '',
    }
  },
  computed:{
    province(){
      return this.$store.state.province;
    },
    city(){
      return this.$store.state.city;
    },
    /**地区是否已选择 */
    area_selected(){
      let {province} = this;
      if(province && province.province && province.provinceCoder){
        return true;
      }else{
        return false;
      }
    },
    area(){
      let area = '请选择省/市';
      let {province, city} = this;
      if(province && province.province && province.provinceCoder){
        area = province.province;
      }
      if(city && city.city && city.coder){
        if(area != '请选择省/市'){
          area += ' ' + city.city;
        }
      }
      return area;
    },
    transform_identity(){
      let {userinfo} = this;
      if(userinfo && userinfo.identity){
        let str = '请选择身份';
        switch(userinfo.identity){
          case 1:
            str = '教师';
            break;
          case 2:
            str = '学生';
            break;
          case 3:
            str = '从业者';
            break;
          default:
            break;
        }
        return str;
      }else{
        return '请选择身份';
      }
    },
    user_data_update(){
      return this.$store.state.user_data_update;
    },
  },
  watch:{
    /**昵称不让输入空格 */
    'userinfo.userName': function(val){
      let userName = val.replace(' ', '');
      this.$set(this.userinfo, 'userName', userName);
    },
    province: {
      handler: function(){},
      deep: true,
    },
    city: {
      handler: function(){},
      deep: true,
    },
    area: {
      handler: function(){},
      deep: true,
    },
    user_data_update: function(val){
      if(val){
        this.$set(this, 'userinfo', this.$tools.deepClone(this.$tools.getCookie('user')));
      }
    }
  },
  methods:{
    /**
     * @description: 修改个人信息
     * @param {String} key 字段名
     * @param {String} val 值
     * @return {type}
     */
    async changeUserInfo(key, val){
      let user = this.$tools.deepClone(this.$tools.getCookie('user'));
      let userinfo = this.userinfo;
      let data = {};
      data[key] = val;
      if(key == 'sex'){
        await this.$http.request('/project/finishInfo', data);
      }
      if(key == 'email'){
        val = val.trim();
        if(val != ''){
          const is_right = this.$regexp.email.test(val);
          if(!is_right){
            this.$notice({desc: '请输入正确的邮箱格式'});
            return;
          }
        }
      }
      if(userinfo[key] != user[key]){
        this.$http.request('/project/finishInfo', data);
      }

      const origin_user = this.$tools.getCookie('user');
      // const userinfo = this.userinfo;
      const new_user = Object.assign(
        {},
        origin_user,
        userinfo,
        {
          vipEndDate: origin_user?.vipEndDate || null,
          vipStartDate: origin_user?.vipEndDate || null,
        }
      )

      this.$tools.setCookie('user', new_user);
      this.$store.commit('set_user', new_user);
    },
    init(){
      let {userinfo} = this;
      // 设置省市信息
      let {provinceCoder, coder, province, city} = userinfo;
      this.$store.commit('set_province', { province, provinceCoder });
      this.$store.commit('set_city', {coder, city});
    },
    /**
     * 获取用户信息
     */
    async getUserInfo(){
      let userId = this.$tools.getUserId();
      let res = await this.$http.request('/project/getUserById', {userId});
      let userinfo = res.contents.user;
      const origin_user = this.$tools.getCookie('user');

      const new_user = Object.assign(
        {},
        origin_user,
        userinfo,
        {
          vipEndDate: origin_user?.vipEndDate || null,
          vipStartDate: origin_user?.vipEndDate || null,
        }
      )
      this.$tools.setCookie('user', new_user);
      this.$set(this, 'userinfo', new_user);
    },
    /**
     * 更新地址信息
     * @param {Boolean} update 是否更新
    */
    async closeSelectArea(update){
      this.switch_select_area = false;
      // console.log(update);
      if(update){
        let {province, city} = this;
        // console.log(province, city)
        let data = {};
        if(province && province.provinceCoder){
          data.provinceCoder = province.provinceCoder;
          // data.province = province.province;
        }
        if(city && city.coder){
          data.coder = city.coder;
          // data.city = city.city;
        }else{
          data.coder = '';
          data.city = '';
        }
        await this.$http.request('/project/finishInfo', data);
      }
    },
    /**
     * @description: 选择身份信息
     * @param {String} val
     * @return {type}
     */
    async selectIdentity(val){
      this.switch_identity=false;
      let userinfo = this.$tools.deepClone(this.userinfo);
      userinfo.identity = val;
      this.$set(this, 'userinfo', userinfo);
      await this.changeUserInfo('identity', val);
    },
    /** 上传头像 */
    async uploadAvatar(){
      let files = this.$refs.upload_avatar.files;
      if(!files[0] || files[0].type.indexOf('image') == -1){
        this.$notice({desc: '请上传图片!', duration: '1500'})
        return;
      }
      let avatar_preview = await this.previewPicture(files[0]);
      this.avatar_preview = avatar_preview;
      let avatar_upload_url = await this.uploadPicture(files[0]);
      this.$set(this.userinfo, 'thumbnail', avatar_upload_url);
      await this.changeUserInfo('thumbnail',avatar_upload_url)
    },
     /**
     *  异步
     *  预览图片
     */
    previewPicture(item) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = (event) => {
          /** 地址 */
          let url = event.target.result;
          resolve(url);
        };
      });
    },
    /**
     * 上传图片
     * @param {Object} file 文件对象
     * @return {String} 图片地址
     */
    async uploadPicture(file) {
      let formdata = new FormData();
      formdata.append("Filedata", file);
      let loginUserCode = this.$tools.getCookie('loginUserCode');
      let config = {
        // 添加请求头
        headers: {
          // loginUserCode: JSON.stringify(loginUserCode),
          loginUserCode: loginUserCode,
        },
      };
      let res = await this.$http.custom_request(
        `https://${this.$domain_name}/project/upLoadPic`,
        formdata,
        config
      );
      // console.log(res);
      let picurl = res.data.contents.picurl;
      /**获取上传的图片地址 */
      let reg = /(\|\|)(\S*)/;
      let uploadUrl = reg.exec(picurl)[2];
      return uploadUrl;
    },
  },
  async mounted(){
    await this.getUserInfo();
    let input_signature = this.$refs.input_signature;
    input_signature.onblur = () => {
      // console.log('blur');
    }
    this.init();

  }
}
</script>
<style lang="css">
.pc_data_popover{
  padding:6px 0!important;
}
.pc_data_popover .identity_list .list{
  box-sizing: border-box;
  transition: all 0.2s;
  cursor: pointer;
  line-height: 30px;
  font-size:15px;
  font-weight:400;
  color:rgba(101,101,101,1);
  padding:0 15px;
}
.pc_data_popover .identity_list .list:hover{
  background-color:#f1f1f1;
}
</style>
<style lang="scss" scoped>
$padding_bottom: 8px;
$margin_bottom: 28px;
.personal_center_data_page{
  // @include flex;
  // justify-content: center;
  // align-items: flex-start;
  width: 100%;
  height: 100vh;
  background-color: #F5F7FA;
  padding: 20px;
  overflow: auto;
  .wrapper{
    @include scrollbar;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    // max-height: 100%;
    // flex:1 ;
  }
  .data_group{
    width: 360px;
    padding-top: 60px;

  }
  .avatar_group_feature{
    width: 100%;
    height: 227px;
    background: {
      image: url(~@/assets/images/personal_center/avatar_background.png);
      position: bottom center;
    }
    .avatar_box{
      position: relative;
      .avatar_img{
        width: 120px;
        height: 120px;
        display: block;
        border-radius:4px;
        position: relative;
        z-index: 2;
      }
      .hover{
        @include transition;
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
        top: 0;
        right: 0;
        border-radius:4px;
        color: #fff;
        background-color: rgba(0,0,0,0.5);
        font-size: 14px;
      }
      &:hover{
        .hover{
          opacity: 1;
        }
      }
    }
    .info_box{
      width: 100%;
      padding-top: 15px;
      font-size: 16px;
      color: #333;
      .placeholder{
        width: 41px;
        height: 33px;
      }
      .vip{
        width: 41px;
        height: 33px;
        display: block;
      }
    }
  }
  .avatar_group{
    @include flex;
    align-items: stretch;
    padding-bottom: $padding_bottom;
    width: 100%;
    .avatar{
      width:90px;
      height:90px;

      display: block;
      // background-color: $img_color;
      margin-right: 15px;
      flex-shrink: 0;
      .avatar_img{
        width: 100%;
        height: 100%;
        display: block;
        border-radius:4px;
      }
    }
    .fun_box{
      @include bbox;
      @include flexsb;
      padding: 11px 0;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      flex: 1;
      .placeholder{
        width: 41px;
        height: 33px;
      }
      .vip{
        width: 41px;
        height: 33px;
        display: block;
      }
      .mod_avatar{
        font-size:14px;

        font-weight:400;
        text-decoration:underline;
        color:rgba(51,51,51,1);
      }
    }
  }
  .line{
    width: 100%;
    height: 2px;
    background-color:$border_color;
    margin-bottom: $margin_bottom;
    margin-top: $padding_bottom;
  }
  .info_group{
    @include flex;
    align-items: flex-start;
    padding-bottom: $padding_bottom;
    .key{
      @include bbox;
      flex-shrink: 0;
      width: 90px;
      text-align: justify;
      text-align-last: justify;
      text-justify: inter-ideograph;
      padding-right: 19px;
      font-size:15px;

      font-weight:400;
      color:rgba(51,51,51,1);
      padding-top:14px;
    }
    .val{
      @include transition;
      padding-top: 14px;
      font-size:15px;

      font-weight:400;
      color:rgba(101,101,101,1);
      background-color: transparent;
      outline: none;
      border:none;
      width: 100%;
      border-bottom: 1px solid transparent;
      &.input{
        padding-bottom: 6px;
        margin-bottom: -6px;
        &:hover{
          border-bottom: 1px solid #ccc;
        }
      }
    }
    .identity_group{
      width: 100%;
    }
    .identity_bar{
      @include transition;
      position: relative;
      padding-top: 14px;
      font-size:15px;
      color:rgba(101,101,101,1);
      width: 100%;
      border-bottom: 1px solid transparent;
      padding-bottom: 6px;
      &:hover{
        border-bottom: 1px solid #ccc;
      }
      .icon{
        @include background(12px, 7px);
        position: absolute;
        top:50%;
        right: 0;
        width: 20px;
        height: 20px;
        background-image: url(~@/assets/images/personal_center/area_arrow.png);
        margin-top: -6px;
      }
    }

    .phone_box{
      @include flexsb;
      width: 100%;
      flex: 1;
      .mod_phone{
        font-size:12px;

        font-weight:400;
        color:rgba(0,83,167,1);
      }
    }
    .input_group{
      position: relative;
      width: 100%;
      flex: 1;
      .input{
        @include bbox;
        @include transition;
        @include placeholder(#ccc);
        font-size:15px;
        font-weight:400;
        color:rgba(101,101,101,1);
        padding-right: 10px;
        width: 100%;
        background-color:transparent;
        border:none;
        outline: none;
        padding: 16px 0 6px;
        border-bottom: 1px solid transparent;
        &:focus, &:hover{
          border-bottom:1px solid #ccc;
        }
      }
    }
    .nickname_box{
      position: relative;
      width: 100%;
      flex: 1;
      .mod_nickname{
        @include bbox;
        @include transition;
        font-size:15px;

        font-weight:400;
        color:rgba(101,101,101,1);
        padding-right: 10px;
        width: 100%;
        background-color:transparent;
        border:none;
        outline: none;
        padding: 16px 0 6px;
        border-bottom: 1px solid transparent;
        &.on, &:hover{
          border-bottom:1px solid #ccc;
        }
      }
    }
    .gender_group{
      @include flex;
      padding-top: 14px;
      .gender_box{
        @include flex;
        cursor: pointer;
        margin-right: 28px;
        &:last-child{
          margin-right: 0px;
        }
        &.on{
          .select{
            background: {
              image: url(~@/assets/images/personal_center/selected.png);
            }
          }
        }
        .select{
          @include transition;
          width:20px;
          height: 20px;
          background: {
            image: url(~@/assets/images/personal_center/select.png);
            size: 16px 16px;
            position: center;
            repeat: no-repeat;
          }
        }
        .icon{
          width: 20px;
          height : 20px;
          margin-left: 8px;
        }
      }

    }
    .area_group{
      flex: 1;
      width: 100%;
      position: relative;
      .select_switch_group{
        @include flexsb;
        width: 100%;
        padding: 14px 0;
        border-bottom: 1px solid #D5D9DB;
        position: relative;
        .select_area_model{
          position: absolute;
          z-index: 99;
          top:0;
          left: 50%;
          margin-left: -315px;
          // left: 0;
        }
        .text{
          font-size:15px;

          font-weight:400;
          color:#343434;
           // 未输入
          &.un_input{
            color: #D6D9DC;
          }
        }
        .icon{
          @include background(12px, 7px);
          @include transition;
          width: 20px;
          height: 20px;
          background: {
            image: url(~@/assets/images/personal_center/area_arrow.png);
          }


        }
      }
    }
    .signature_group{
      position: relative;
      width: 100%;
      flex: 1;
      height:80px;
      border:1px solid rgba(213,217,219,1);
      border-radius:4px;
      font-size:15px;

      font-weight:400;
      color:rgba(102,102,102,1);
      // overflow: hidden;
      .signature{
        @include bbox;
        padding: 10px;
        position: relative;
        width: 100%;
        word-break: break-all;
        visibility: hidden;
        text-align: justify;
        text-justify: inter-ideograph;
        text-align-last: left;
      }
      .input_signature{
        @include bbox;
        @include scrollbar;
        overflow-y: auto;
        z-index: 2;
        padding: 10px;
        position: absolute;
        top:0;
        left:0;
        resize: none;
        width: 100%;
        height: 100%;
        height: 80px;
        border:none;
        background-color: transparent;
        outline: none;
        font-size:15px;

        font-weight:400;
        color:rgba(102,102,102,1);
        text-align: justify;
        text-justify: inter-ideograph;
        text-align-last: left;
      }
    }
    .id_group{
      @include flexsb;
      width: 100%;
      flex: 1;
      .mod{
        font-size:12px;

        font-weight:400;
        color:rgba(0,84,167,1);
      }
    }
  }
  .extend_group{
    .extend_title_group{
      @include flex;
      padding-bottom: $padding_bottom;
      &.on{
        .icon{
          background-size: 10px 8px;
          background-image: url(~@/assets/images/personal_center/open.png);
        }
      }
      .icon{
        @include background(8px, 10px);
        // @include transition;
        width: 20px;
        height: 20px;
        background-image: url(~@/assets/images/personal_center/close.png);
      }
      .title{
        font-size:15px;

        font-weight:400;
        color:rgba(51,51,51,1);
      }
    }
  }
  .growing_data_wrap{
    position: relative;
    width: 100%;
    height: 500px;
    margin-top: 20px;
  }
  .growing_data_group{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    // width: 100%;
    width: 600px;
    height: 500px;
  }
  .mod_phone_group{
    .title_group{
      width: 100%;
      text-align: center;
      font-size:18px;

      font-weight:bold;
      color:rgba(51,51,51,1);
    }
    .info_group{
      @include flex;
      width: 100%;
      .key{
        width:120px;
        flex-shrink: 0;
      }
      .input_box{
        @include flex;
        width: 100%;
        flex: 1;
        .input{
          @include bbox;
          width: 100%;
          flex: 1;
          background-color:#fbfbfb;
          height:46px;
          background:rgba(251,251,251,1);
          border:1px solid rgba(240,240,240,1);
          border-radius:4px;
          line-height: 44px;
          font-size:14px;

          font-weight:400;
        }
        .btn{
          @include bbox;
          @include transition;
          text-align: center;
          flex-shrink: 0;
          margin-left: 14px;
          width:106px;
          height:46px;
          line-height: 44px;
          background:rgba(251,251,251,1);
          border:1px solid rgba(0,84,167,1);
          border-radius:4px;
          font-size:14px;

          font-weight:400;
          color:rgba(0,84,167,1);
          &:hover{
            background-color: #E5EEF6;
          }
        }
      }
    }
    .footer_group{
      @include flex;
      width: 100%;
      justify-content: center;
      .btn{
        @include transition;
        text-align: center;
        width:160px;
        line-height:50px;
        background:rgba(204,204,204,1);
        border-radius:4px;
        margin: 0 20px;
        font-size:18px;

        font-weight:400;
        color:rgba(255,255,255,1);
        &.cancel{
          background:rgba(0,84,167,0.1);
          color: $main_blue;
        }
      }
    }
  }
}
</style>
