var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userinfo
    ? _c("div", { staticClass: "personal_center_data_page bbox" }, [
        _c("div", { staticClass: "wrapper d-flex flex-column" }, [
          _c(
            "div",
            {
              staticClass:
                "avatar_group_feature flex-shrink-0 d-flex flex-column align-center justify-center",
            },
            [
              _c("div", { staticClass: "avatar_box cp" }, [
                _vm.avatar_preview
                  ? _c("img", {
                      staticClass: "avatar_img",
                      attrs: { src: _vm.avatar_preview, alt: "" },
                    })
                  : _vm._e(),
                _vm.userinfo.thumbnail && !_vm.avatar_preview
                  ? _c("img", {
                      staticClass: "avatar_img",
                      attrs: {
                        src: `${_vm.$avatar_url}${_vm.userinfo.thumbnail}`,
                        alt: "",
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "hover un_sel d-flex align-center justify-center",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.$refs.upload_avatar.click()
                      },
                    },
                  },
                  [_c("div", { staticClass: "text" }, [_vm._v("修改头像")])]
                ),
                _c("input", {
                  ref: "upload_avatar",
                  staticClass: "upload_avatar",
                  staticStyle: { display: "none" },
                  attrs: { type: "file", accept: "image/*" },
                  on: { change: _vm.uploadAvatar },
                }),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "info_box d-flex align-start justify-center bbox",
                },
                [
                  _c("div", { staticClass: "placeholder" }),
                  _c("div", { staticClass: "username" }, [
                    _vm._v(_vm._s(_vm.userinfo.userName)),
                  ]),
                  _vm.userinfo.userVip
                    ? _c("img", {
                        staticClass: "vip cp",
                        attrs: {
                          src: require("@/assets/images/personal_center/vip_mark.png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.$router.push({
                              name: "personal_center_vip_and_consumption",
                            })
                          },
                        },
                      })
                    : _c("div", { staticClass: "placeholder" }),
                ]
              ),
            ]
          ),
          0
            ? _c("div", { staticClass: "avatar_group" }, [
                _c("div", { staticClass: "avatar" }, [
                  _vm.avatar_preview
                    ? _c("img", {
                        staticClass: "avatar_img",
                        attrs: { src: _vm.avatar_preview, alt: "" },
                      })
                    : _vm._e(),
                  _vm.userinfo.thumbnail && !_vm.avatar_preview
                    ? _c("img", {
                        staticClass: "avatar_img",
                        attrs: {
                          src: `${_vm.$avatar_url}${_vm.userinfo.thumbnail}`,
                          alt: "",
                        },
                      })
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "fun_box" }, [
                  _vm.userinfo.userVip
                    ? _c("img", {
                        staticClass: "vip",
                        attrs: {
                          src: require("@/assets/images/personal_center/vip_mark.png"),
                          alt: "",
                        },
                      })
                    : _c("div", { staticClass: "placeholder" }),
                  _c(
                    "span",
                    {
                      staticClass: "mod_avatar cp un_sel",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$refs.upload_avatar.click()
                        },
                      },
                    },
                    [_vm._v("修改头像")]
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "data_group" },
            [
              _vm._m(0),
              _c("div", { staticClass: "info_group" }, [
                _c("div", { staticClass: "key" }, [_vm._v("妙行号:")]),
                _c("div", { staticClass: "phone_box" }, [
                  _c("div", { staticClass: "val" }, [
                    _vm._v(_vm._s(_vm.userinfo.phone)),
                  ]),
                  0
                    ? _c(
                        "div",
                        {
                          staticClass: "mod_phone cp",
                          on: {
                            click: function ($event) {
                              _vm.dialogFormVisible = true
                            },
                          },
                        },
                        [_vm._v("修改绑定手机 >")]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "info_group",
                  staticStyle: { "margin-bottom": "-6px" },
                },
                [
                  _c("div", { staticClass: "key" }, [_vm._v("昵称:")]),
                  _c("div", { staticClass: "nickname_box" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userinfo.userName,
                          expression: "userinfo.userName",
                        },
                      ],
                      ref: "userName",
                      staticClass: "mod_nickname",
                      class: { on: _vm.switch_mod_nickname },
                      attrs: { type: "text", maxlength: "20" },
                      domProps: { value: _vm.userinfo.userName },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.$refs.userName.blur()
                        },
                        focus: function ($event) {
                          _vm.switch_mod_nickname = true
                        },
                        blur: function ($event) {
                          _vm.changeUserInfo("userName", _vm.userinfo.userName)
                          _vm.switch_mod_nickname = false
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.userinfo,
                            "userName",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c("div", { staticClass: "info_group" }, [
                _c("div", { staticClass: "key" }, [_vm._v("性别:")]),
                _c("div", { staticClass: "gender_group" }, [
                  _c(
                    "div",
                    {
                      staticClass: "female_group gender_box",
                      class: { on: _vm.userinfo.userSex == "女" },
                      on: {
                        click: function ($event) {
                          _vm.$set(_vm.userinfo, "userSex", "女")
                          _vm.changeUserInfo("sex", "女")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "select" }),
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/personal_center/female.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "male_group gender_box",
                      class: { on: _vm.userinfo.userSex == "男" },
                      on: {
                        click: function ($event) {
                          _vm.$set(_vm.userinfo, "userSex", "男")
                          _vm.changeUserInfo("sex", "男")
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "select" }),
                      _c("img", {
                        staticClass: "icon",
                        attrs: {
                          src: require("@/assets/images/personal_center/male.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "info_group" }, [
                _c("div", { staticClass: "key" }, [_vm._v("邮箱:")]),
                _c("div", { staticClass: "input_group" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.userinfo.email,
                        expression: "userinfo.email",
                      },
                    ],
                    ref: "email",
                    staticClass: "input",
                    attrs: { type: "text", placeholder: "请输入邮箱地址" },
                    domProps: { value: _vm.userinfo.email },
                    on: {
                      blur: function ($event) {
                        return _vm.changeUserInfo("email", _vm.userinfo.email)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.userinfo, "email", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c(
                "div",
                {
                  staticClass: "info_group",
                  staticStyle: { "padding-bottom": "14px" },
                },
                [
                  _c("div", { staticClass: "key" }, [_vm._v("地区:")]),
                  _c("div", { staticClass: "area_group" }, [
                    _c(
                      "div",
                      {
                        staticClass: "select_switch_group cp",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.switch_select_area = true
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text",
                            class: { un_input: !_vm.area_selected },
                          },
                          [_vm._v(_vm._s(_vm.area))]
                        ),
                        _c("div", { staticClass: "icon" }),
                        _vm.switch_select_area
                          ? _c(
                              "div",
                              { staticClass: "select_area_model" },
                              [
                                _c("select-area", {
                                  on: { closeSelectArea: _vm.closeSelectArea },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "info_group",
                  staticStyle: { "align-items": "flex-start" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "key",
                      staticStyle: { "padding-top": "14px" },
                    },
                    [_vm._v("个性签名:")]
                  ),
                  _c("div", { staticClass: "signature_group" }, [
                    _c("div", { staticClass: "signature" }, [
                      _vm._v(_vm._s(_vm.userinfo.personalDescribe)),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.userinfo.personalDescribe,
                          expression: "userinfo.personalDescribe",
                        },
                      ],
                      ref: "input_signature",
                      staticClass: "input_signature",
                      attrs: { name: "", id: "" },
                      domProps: { value: _vm.userinfo.personalDescribe },
                      on: {
                        blur: function ($event) {
                          return _vm.changeUserInfo(
                            "personalDescribe",
                            _vm.userinfo.personalDescribe
                          )
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.userinfo,
                            "personalDescribe",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _c("div", { staticClass: "line" }),
              _c(
                "div",
                { staticClass: "extend_group" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "extend_title_group cp un_sel",
                      class: { on: _vm.switch_realname },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.switch_realname = !_vm.switch_realname
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "icon" }),
                      _c("div", { staticClass: "title" }, [
                        _vm._v("实名认证信息"),
                      ]),
                    ]
                  ),
                  _vm.switch_realname
                    ? [
                        _c("div", { staticClass: "info_group" }, [
                          _c("div", { staticClass: "key" }, [_vm._v("姓名:")]),
                          _c("div", { staticClass: "val" }, [
                            _vm._v(_vm._s(_vm.userinfo.userRealName)),
                          ]),
                        ]),
                        _c("div", { staticClass: "info_group" }, [
                          _c("div", { staticClass: "key" }, [
                            _vm._v("身份证号:"),
                          ]),
                          _c("div", { staticClass: "id_group" }, [
                            _c("div", { staticClass: "val" }, [
                              _vm._v(_vm._s(_vm.userinfo.userCardNo)),
                            ]),
                            0
                              ? _c("div", { staticClass: "mod" }, [
                                  _vm._v("修改实名认证 >"),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "line" }),
              _c(
                "div",
                { staticClass: "extend_group" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "extend_title_group cp un_sel",
                      class: { on: _vm.switch_otherinfo },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.switch_otherinfo = !_vm.switch_otherinfo
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "icon" }),
                      _c("div", { staticClass: "title" }, [_vm._v("其他信息")]),
                    ]
                  ),
                  _vm.switch_otherinfo
                    ? [
                        _c("div", { staticClass: "info_group" }, [
                          _c("div", { staticClass: "key" }, [_vm._v("机构:")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userinfo.ownedEnterprises,
                                expression: "userinfo.ownedEnterprises",
                              },
                            ],
                            ref: "ownedEnterprises",
                            staticClass: "val input",
                            attrs: { type: "text" },
                            domProps: { value: _vm.userinfo.ownedEnterprises },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.$refs.ownedEnterprises.blur()
                              },
                              blur: function ($event) {
                                return _vm.changeUserInfo(
                                  "ownedEnterprises",
                                  _vm.userinfo.ownedEnterprises
                                )
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.userinfo,
                                  "ownedEnterprises",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c("div", { staticClass: "info_group" }, [
                          _c("div", { staticClass: "key" }, [_vm._v("职务:")]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.userinfo.userPosition,
                                expression: "userinfo.userPosition",
                              },
                            ],
                            ref: "userPosition",
                            staticClass: "val input",
                            attrs: { type: "text" },
                            domProps: { value: _vm.userinfo.userPosition },
                            on: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.$refs.userPosition.blur()
                              },
                              blur: function ($event) {
                                return _vm.changeUserInfo(
                                  "userPosition",
                                  _vm.userinfo.userPosition
                                )
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.userinfo,
                                  "userPosition",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _c(
                          "div",
                          { staticClass: "info_group" },
                          [
                            _c("div", { staticClass: "key" }, [
                              _vm._v("身份:"),
                            ]),
                            _c(
                              "el-popover",
                              {
                                staticClass: "identity_group",
                                attrs: {
                                  placement: "bottom",
                                  trigger: "click",
                                  width: "270",
                                  "popper-class": "pc_data_popover",
                                },
                                model: {
                                  value: _vm.switch_identity,
                                  callback: function ($$v) {
                                    _vm.switch_identity = $$v
                                  },
                                  expression: "switch_identity",
                                },
                              },
                              [
                                _c("div", { staticClass: "identity_list" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "list",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.selectIdentity(1)
                                        },
                                      },
                                    },
                                    [_vm._v("教师")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "list",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.selectIdentity(2)
                                        },
                                      },
                                    },
                                    [_vm._v("学生")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "list",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.selectIdentity(3)
                                        },
                                      },
                                    },
                                    [_vm._v("从业者")]
                                  ),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "identity_bar cp",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c("div", { staticClass: "text" }, [
                                      _vm._v(_vm._s(_vm.transform_identity)),
                                    ]),
                                    _c("div", { staticClass: "icon" }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "line" }),
              1
                ? _c("div", { staticClass: "extend_group" }, [
                    _c(
                      "div",
                      {
                        staticClass: "extend_title_group cp un_sel",
                        class: { on: _vm.switch_growing_data },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            _vm.switch_growing_data = !_vm.switch_growing_data
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "icon" }),
                        _c("div", { staticClass: "title" }, [
                          _vm._v("个人能力成长数据"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.switch_growing_data,
                            expression: "switch_growing_data",
                          },
                        ],
                        staticClass: "growing_data_wrap",
                      },
                      [
                        _c("radar-growing-data", {
                          attrs: { userId: Number(_vm.$tools.getUserId()) },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "el-dialog",
                {
                  staticClass: "mod_phone_group",
                  attrs: {
                    title: "修改绑定手机",
                    visible: _vm.dialogFormVisible,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogFormVisible = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "title_group",
                      attrs: { slot: "title" },
                      slot: "title",
                    },
                    [_vm._v("修改手机")]
                  ),
                  _c("div", { staticClass: "mod_phone_box" }, [
                    _c("div", { staticClass: "info_group" }, [
                      _c("div", { staticClass: "key" }, [_vm._v("手机号码:")]),
                      _c("div", { staticClass: "input_box" }, [
                        _c("input", {
                          staticClass: "input",
                          attrs: { type: "text" },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "info_group" }, [
                      _c("div", { staticClass: "key" }, [
                        _vm._v("短信验证码:"),
                      ]),
                      _c("div", { staticClass: "input_box" }, [
                        _c("input", {
                          staticClass: "input",
                          attrs: { type: "text" },
                        }),
                        _c("div", { staticClass: "btn cp un_sel" }, [
                          _vm._v("点击获取"),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "footer_group",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "btn cp un_sel sure",
                          on: {
                            click: function ($event) {
                              _vm.dialogFormVisible = false
                            },
                          },
                        },
                        [_vm._v("确认")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "btn cp un_sel cancel",
                          on: {
                            click: function ($event) {
                              _vm.dialogFormVisible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "extend_group" }, [
      _c("div", { staticClass: "extend_title_group cp un_sel" }, [
        _c("div", { staticClass: "title" }, [_vm._v("基本信息")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }